<template>
  <base-card>
    <form @submit.prevent="submitForm">
      <div v-if="mode === 'signup'" class="form-group">
        <div class="form-group">
          <label for="firstName">Firstname</label>
          <input
            type="text"
            id="firstName"
            v-model="firstName"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="lastName">LastName</label>
          <input
            type="text"
            id="lastName"
            v-model="lastName"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="phoneNumber">Phone number</label>
          <input
            type="tel"
            placeholder="+256..."
            id="phoneNumber"
            v-model.trim="phoneNumber"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
          v-model="email"
        />
        <!-- <small id="emailHelp" class="form-text text-muted"
          >We'll never share your email with anyone else.</small
        > -->
      </div>
      <p v-if="error" class="red">{{ error }}</p>
      <div class="form-group">
        <label for="password">Create a password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
        />
      </div>
      <div class="my-3">
        <p v-if="!formIsValid" style="color: red">
          Please Enter all the required information!
        </p>
        <base-button>{{ submitButtonCaption }}</base-button>
        <base-button type="button" mode="flat" @click="switchAuthMode">{{
          switchModeButtonCaption
        }}</base-button>
      </div>
    </form>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      formIsValid: true,
      mode: "login",
      isLoading: false,
      error: null,
    };
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === "login") {
        return "login";
      } else {
        return "signup";
      }
    },
    switchModeButtonCaption() {
      if (this.mode === "login") {
        return "signup instead";
      } else {
        return "login instead";
      }
    },
  },
  methods: {
    validateForm() {
      this.formIsValid = true;

      if (this.firstName === "") {
        this.formIsValid = false;
      }
      if (this.lastName === "") {
        this.formIsValid = false;
      }
      if (this.phoneNumber === "") {
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;

      const actionPayload = {
        email: this.email,
        password: this.password,
      };
      try {
        if (this.mode === "login") {
          await this.$store.dispatch("login", actionPayload);
        } else {
          this.validateForm();
          if (!this.formIsValid) {
            return;
          }
          await this.$store.dispatch("signup", {
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
          });
        }
        const redirectUrl = "/" + (this.$route.query.redirect || "rentals");
        this.$router.replace(redirectUrl);
      } catch (err) {
        // console.log('Here is the problem')
        this.error = err.message || "Failed to authenticate";
        throw this.error;
      }
      this.isLoading = false;
    },
    switchAuthMode() {
      if (this.mode === "login") {
        this.mode = "signup";
      } else {
        this.mode = "login";
      }
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}
</style>